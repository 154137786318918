import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { useSettings } from '@wix/tpa-settings/react';
import { Confirm as ConfirmIcon } from '@wix/wix-ui-icons-common';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Button, ButtonPriority, ButtonProps, ButtonSize } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { SimpleReward } from '../../../types/domain';
import settingsParams from '../settingsParams';
import { useNavigationConfig } from './hooks';
import { classes } from './list-item-button.st.css';
import { RewardProgress } from './reward-progress';

interface ListItemProps {
  onClick(): void;
  reward: SimpleReward;
  showCopied?: boolean;
  isCheckoutDiscountReward: boolean;
  hasEnoughPoints: boolean;
}

export const ListItemButton: React.FC<ListItemProps> = ({
  onClick,
  reward,
  showCopied,
  isCheckoutDiscountReward,
  hasEnoughPoints,
}) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { pageLinks } = useNavigationConfig();
  const isDiscountReward = [RewardType.DISCOUNT_AMOUNT, RewardType.SPI_DISCOUNT_AMOUNT].includes(reward.type!);
  const isCouponReward = reward.type === RewardType.COUPON_REWARD;
  const isRedeemedCouponReward = reward.couponActivated;

  const handleClick: ButtonProps['onClick'] = (event) => {
    event.preventDefault();
    onClick();
  };

  const commonButtonProps = {
    size: ButtonSize.small,
    upgrade: true,
    onClick: handleClick,
  };

  if (isDiscountReward && hasEnoughPoints) {
    if (isCheckoutDiscountReward) {
      return (
        <Button
          {...commonButtonProps}
          className={classes.secondaryButton}
          priority={ButtonPriority.secondary}
          as="a"
          href={pageLinks.applyRewardHref}
        >
          {settings.get(settingsParams.continueButton)}
        </Button>
      );
    } else {
      return (
        <Button
          {...commonButtonProps}
          data-hook={DataHook.RewardsListItemButtonApplyReward}
          className={classes.primaryButton}
        >
          {settings.get(settingsParams.button)}
        </Button>
      );
    }
  }

  if (isCouponReward) {
    if (isRedeemedCouponReward) {
      return (
        <Button
          {...commonButtonProps}
          priority={ButtonPriority.secondary}
          data-hook={DataHook.RewardsListItemButtonCouponCopy}
          className={classes.secondaryButton}
        >
          <span className={showCopied ? classes.container : ''}>
            {showCopied && (
              <ConfirmIcon
                size="24px"
                className={classes.confirmIcon}
                role="status"
                aria-label={t('app.coupon-code-copied')}
              />
            )}
            <span className={showCopied ? classes.buttonText : ''}>{settings.get(settingsParams.copyButton)}</span>
          </span>
        </Button>
      );
    } else if (hasEnoughPoints) {
      return (
        <Button
          {...commonButtonProps}
          data-hook={DataHook.RewardsListItemButtonCouponGet}
          className={classes.primaryButton}
        >
          {settings.get(settingsParams.getButton)}
        </Button>
      );
    }
  }

  return <RewardProgress reward={reward} />;
};
