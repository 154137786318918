import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import settingsParams from '../settingsParams';
import { Divider } from './divider';
import { classes } from './error-state.st.css';

interface ErrorStateProps {
  message: string;
}

export const ErrorState: React.FC<ErrorStateProps> = ({ message }) => {
  const { t } = useTranslation();
  const settings = useSettings();

  const titleTag = settings.get(settingsParams.emptyStateTitleTag);
  const subtitleTag = settings.get(settingsParams.emptyStateSubtitleTag);

  return (
    <div className={classes.root}>
      <Divider />
      <div className={classes.content}>
        <Text tagName={titleTag} className={classes.title}>
          {t('app.error-state.service-not-available')}
        </Text>
        <Text tagName={subtitleTag} className={classes.description}>
          {message}
        </Text>
      </div>
      <Divider />
    </div>
  );
};
