import { useLocale } from './context-hooks';

export const useDateFormatter = () => {
  const { locale, timeZone } = useLocale();

  const formatDate = (date: Date) => {
    try {
      const dateFormatter = new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
        timeZone,
      });

      return dateFormatter.format(date);
    } catch {
      return date.toLocaleDateString();
    }
  };

  return { formatDate };
};
