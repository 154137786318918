import React from 'react';

import { DataHook } from '../../../types/data-hook';
import { useAccount, useRewards } from './hooks/context-hooks';
import { ListItem } from './list-item';
import { classes } from './rewards-list.st.css';

export const RewardsList = () => {
  const { simpleRewards } = useRewards();
  const { account } = useAccount();

  const rewards = simpleRewards
    .filter((reward) => reward.tierId === account.tierId || reward.couponActivated)
    .map((reward, index, list) => (
      <ListItem key={reward.id!} reward={reward} isLastReward={index === list.length - 1} />
    ));

  return (
    <ul data-hook={DataHook.RewardsList} className={classes.root}>
      {rewards}
    </ul>
  );
};
