import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { ProgressBar, Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { SimpleTier } from '../../../types/domain';
import { getNextTierId } from '../../../utils';
import settingsParams from '../settingsParams';
import { useNumberFormatter } from './hooks';
import { useAccount, useLoyaltyProgram, useTiersProgram } from './hooks/context-hooks';
import { buildImageSrc } from './points-card';
import { classes, style } from './tier-card.st.css';

export const TierCard: React.FC = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { program } = useLoyaltyProgram();
  const { isMobile } = useEnvironment();
  const { programSettings, tiers } = useTiersProgram();
  const { account } = useAccount();
  const { formatNumber } = useNumberFormatter();
  const accountTotalPoints = account.tiersPeriodPoints;
  const currentTierId = account.tierId;
  const { nextTierId } = getNextTierId(tiers, currentTierId);
  const currentTierTitleTag = settings.get(settingsParams.tiersCurrentTierTitleTag);
  const tierNameTag = settings.get(settingsParams.tiersTierNameTag);

  const getCurrentTier = (): SimpleTier => {
    if (!currentTierId) {
      return {
        tierDefinition: programSettings?.baseTierDefinition,
      };
    }

    const tier = tiers?.find(({ id }) => id === currentTierId);

    return {
      id: tier?.id,
      tierDefinition: tier?.tierDefinition,
    };
  };
  const getNextTier = (): SimpleTier | undefined => {
    if (!nextTierId) {
      return undefined;
    }

    const tier = tiers?.find(({ id }) => id === nextTierId);

    return {
      id: tier?.id,
      requiredPoints: tier?.requiredPoints,
      tierDefinition: tier?.tierDefinition,
    };
  };
  const currentTier = getCurrentTier();
  const nextTier = getNextTier();

  const TotalPoints: React.FC = () => {
    if (nextTier) {
      return (
        <>
          {formatNumber(accountTotalPoints ?? 0)} / {formatNumber(nextTier.requiredPoints ?? 0)}
        </>
      );
    }

    return <>{formatNumber(accountTotalPoints ?? 0)}</>;
  };

  return (
    <div className={style(classes.root, { isMobile })}>
      <div className={classes.contentContainer}>
        <Text tagName={currentTierTitleTag} className={classes.currentTierText}>
          {t('app.tiers-widget.your-current-tier')}
        </Text>
        <div className={classes.tierContainer}>
          {currentTier.tierDefinition?.icon?.url ? (
            <img
              className={classes.icon}
              aria-hidden
              alt=""
              src={buildImageSrc(currentTier.tierDefinition?.icon?.url)}
            />
          ) : null}
          <Text tagName={tierNameTag} className={classes.tier} data-hook={DataHook.PointsCardCurrentTierName}>
            {currentTier.tierDefinition?.name}
          </Text>
        </div>
        {isMobile ? (
          <>
            <div className={classes.tierTotalPointsContainer}>
              <Text className={classes.totalPointsText}>
                {t(
                  program.pointDefinition?.customName
                    ? 'app.tiers-widget.total-points-earned-custom'
                    : 'app.tiers-widget.total-points-earned',
                  {
                    pointsName: program.pointDefinition?.customName,
                  },
                )}
              </Text>
              <Text className={classes.totalPointsCount} data-hook={DataHook.PointsCardTotalEarnedPoints}>
                <TotalPoints />
              </Text>
            </div>
            <div className={classes.progressBarContainer}>
              <ProgressBar
                value={nextTier ? (accountTotalPoints! / nextTier.requiredPoints!) * 100 : 100}
                showProgressIndication
              />
            </div>
            {nextTier ? (
              <Text className={classes.nextTier} data-hook={DataHook.PointsCardNextTierName}>
                {t('app.tiers-widget.next-tier', { tierName: nextTier.tierDefinition?.name })}
              </Text>
            ) : null}
          </>
        ) : (
          <>
            <div className={classes.pointsAndNextContainer}>
              <div className={classes.tierTotalPointsContainer}>
                <Text className={classes.totalPointsText}>
                  {t(
                    program.pointDefinition?.customName
                      ? 'app.tiers-widget.total-points-earned-custom'
                      : 'app.tiers-widget.total-points-earned',
                    {
                      pointsName: program.pointDefinition?.customName,
                    },
                  )}
                </Text>
                <Text className={classes.totalPointsCount} data-hook={DataHook.PointsCardTotalEarnedPoints}>
                  <TotalPoints />
                </Text>
              </div>
              {nextTier ? (
                <Text className={classes.nextTier} data-hook={DataHook.PointsCardNextTierName}>
                  {t('app.tiers-widget.next-tier', { tierName: nextTier.tierDefinition?.name })}
                </Text>
              ) : null}
            </div>
            <div className={classes.progressBarContainer}>
              <ProgressBar
                value={nextTier ? (accountTotalPoints! / nextTier.requiredPoints!) * 100 : 100}
                showProgressIndication
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
