import { Status } from '@wix/ambassador-loyalty-v1-tier/types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text, TextButton, TextButtonProps } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import {
  useAccount,
  useLoyaltyProgram,
  useNavigationActions,
  useNavigationConfig,
  useNumberFormatter,
  useTiersProgram,
} from './hooks';
import { classes, style } from './points-card.st.css';
import { PointsExpiration } from './points-expiration';

export const buildImageSrc = (path: string) => `https://static.wixstatic.com/${path}`;

export const PointsCard: React.FC = () => {
  const { t } = useTranslation();
  const { program } = useLoyaltyProgram();
  const { programSettings } = useTiersProgram();
  const styles = useStyles();
  const settings = useSettings();
  const { account } = useAccount();
  const { navigateToLoyaltyPage } = useNavigationActions();
  const { pageLinks } = useNavigationConfig();
  const { isMobile } = useEnvironment();
  const { formatNumber } = useNumberFormatter();
  const totalPointsTotalNumberFont = styles.get(stylesParams.totalPointsTotalNumberFont);
  const iconWidth = Math.max(30, (totalPointsTotalNumberFont.size || 0) * 0.8);
  const iconUrl = program.pointDefinition?.icon?.url;
  const showTiers = programSettings?.status === Status.ACTIVE;
  const pointsName = program.pointDefinition?.customName;
  const totalPointsTitleTag = settings.get(settingsParams.totalPointsTitleTag);

  const handleLearnMoreClick: TextButtonProps['onClick'] = (event) => {
    event.preventDefault();
    navigateToLoyaltyPage();
  };

  return (
    <div data-hook={DataHook.PointsCard} className={style(classes.root, { showTiers, isMobile })}>
      <div className={classes.contentContainer}>
        {showTiers && (
          <Text
            tagName={totalPointsTitleTag}
            data-hook={DataHook.PointsCardDescription}
            className={classes.description}
          >
            {t(pointsName ? 'app.points-card.available-points-custom' : 'app.points-card.available-points', {
              pointsName,
            })}
          </Text>
        )}
        <div data-hook={DataHook.PointsCardPointsBalance} className={classes.pointsBalance}>
          {!!iconUrl && (
            <div className={classes.icon} aria-hidden>
              <img width={iconWidth} src={buildImageSrc(iconUrl)} alt="" />
            </div>
          )}
          <Text className={classes.points}>{formatNumber(account.pointsBalance ?? 0)}</Text>
        </div>
        {!showTiers && (
          <Text
            tagName={totalPointsTitleTag}
            data-hook={DataHook.PointsCardDescription}
            className={classes.description}
          >
            {t(pointsName ? 'app.points-card.total-points-earned-custom' : 'app.points-card.total-points-earned', {
              pointsName,
            })}
          </Text>
        )}
        <PointsExpiration tiersEnabled={showTiers} />
        <div className={classes.cta} data-hook={DataHook.ButtonLearnMoreWrapper}>
          <TextButton
            as="a"
            href={pageLinks.loyaltyPageHref}
            data-hook={DataHook.ButtonLearnMore}
            className={classes.linkToLoyalty}
            onClick={handleLearnMoreClick}
          >
            {pointsName
              ? t('app.points-card.learn-how-to-earn-custom', { pointsName })
              : t('app.points-card.learn-how-to-earn')}
          </TextButton>
        </div>
      </div>
    </div>
  );
};
