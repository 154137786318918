import { useEnvironment } from '@wix/yoshi-flow-editor';
import React, { useEffect } from 'react';
import { Toast as TPAToast, ToastPlacement, ToastSkin } from 'wix-ui-tpa/cssVars';

import { ToastType } from '../../../constants';
import { useToast, useToastActions } from './hooks/context-hooks';
import { classes, st } from './toast.st.css';

const skinByToastTypeMap: Record<ToastType, ToastSkin> = {
  [ToastType.Success]: ToastSkin.success,
  [ToastType.Error]: ToastSkin.error,
};

export const Toast: React.FC = () => {
  const { isMobile } = useEnvironment();
  const { hideToast } = useToastActions();
  const { isVisible, type, message } = useToast();

  const skin = skinByToastTypeMap[type];

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(hideToast, 5000);
      return () => clearTimeout(timeout);
    }
  }, [message]);

  return (
    <TPAToast
      skin={skin}
      children={message}
      onClose={() => hideToast()}
      className={st(classes.toast, {
        mobile: isMobile,
      })}
      shouldShowCloseButton
      isShown={isVisible}
      placement={isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline}
    />
  );
};
