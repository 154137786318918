import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React, { useEffect, useState } from 'react';
import { Button, ButtonPriority, Dialog, Text, ThreeDotsLoader } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../../../types/data-hook';
import { SimpleReward } from '../../../../../types/domain';
import { RequestStatus } from '../../../../../types/store';
import { ModalType } from '../../hocs/modal-context';
import { useNumberFormatter } from '../../hooks';
import { useCoupons, useLoyaltyProgram, useRewards } from '../../hooks/context-hooks';
import { useModal } from '../../hooks/use-modal';
import { classes, style } from './get-coupon-modal.st.css';

export interface GetCouponModalType {
  type: ModalType.GET_COUPON;
  props: {
    reward: SimpleReward;
    onConfirm(): void;
  };
}

interface GetCouponModalProps {
  onClose(): void;
  onConfirm(): void;
  reward: SimpleReward;
}

export const GetCouponModal: React.FC<GetCouponModalProps> = ({ onClose, onConfirm, reward }) => {
  const { t } = useTranslation();
  const { redeemCouponStatus } = useCoupons();
  const { simpleRewards } = useRewards();
  const { showModal } = useModal();
  const { program } = useLoyaltyProgram();
  const { isMobile } = useEnvironment();
  const [rewardsLength] = useState(simpleRewards.length);
  const { formatNumber } = useNumberFormatter();
  const pointsName = program.pointDefinition?.customName;
  const { requiredPoints } = reward;

  useEffect(() => {
    if (redeemCouponStatus === RequestStatus.SUCCESS && simpleRewards.length > rewardsLength) {
      onClose();
      showModal({
        type: ModalType.COUPON_CODE,
        props: {
          couponCode: simpleRewards[0].couponCode!,
          couponName: simpleRewards[0].couponName!,
        },
      });
    }
  }, [simpleRewards, redeemCouponStatus]);

  const title = pointsName
    ? t('app.get-coupon-modal.title-custom-points', {
        formattedPoints: formatNumber(requiredPoints ?? 0),
        pointsName,
      })
    : t('app.get-coupon-modal.title', {
        formattedPoints: formatNumber(requiredPoints ?? 0),
        pointsRequired: requiredPoints,
      });

  return (
    <Dialog
      isOpen
      onClose={onClose}
      notFullscreenOnMobile={isMobile}
      aria-label={title}
      closeButtonAriaLabel={t('app.modal.close')}
      data-hook={DataHook.RedeemPointsModal}
    >
      <div className={style(classes.modal, { isMobile })}>
        <div className={classes.textsContainer}>
          <Text tagName="h2" className={classes.header}>
            {title}
          </Text>
          <Text tagName="p" className={classes.description}>
            {t('app.get-coupon-modal.description', { couponName: reward.title })}
          </Text>
        </div>
        <div className={classes.buttons}>
          <Button
            priority={ButtonPriority.basicSecondary}
            upgrade
            className={classes.secondaryButton}
            data-hook={DataHook.RedeemPointsModalNotNowButton}
            onClick={onClose}
          >
            {t('app.get-coupon-modal.cancel-button')}
          </Button>
          <Button
            upgrade
            className={classes.primaryButton}
            onClick={onConfirm}
            data-hook={DataHook.RedeemPointsModalRedeemButton}
            disabled={redeemCouponStatus === RequestStatus.LOADING}
          >
            {redeemCouponStatus === RequestStatus.LOADING ? (
              <ThreeDotsLoader />
            ) : (
              t('app.get-coupon-modal.redeem-button')
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
