import { Status as PointsExpirationStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { useSettings } from '@wix/tpa-settings/react';
import { Info as InfoIcon } from '@wix/wix-ui-icons-common/on-stage';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { useState } from 'react';
import { IconTooltip, Text } from 'wix-ui-tpa/cssVars';

import settingsParams from '../settingsParams';
import { useAccount, useDateFormatter, useLoyaltyProgram, useNumberFormatter } from './hooks';
import { classes, style } from './points-expiration.st.css';

const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

interface PointsExpirationProps {
  tiersEnabled: boolean;
}

export const PointsExpiration: React.FC<PointsExpirationProps> = ({ tiersEnabled }) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const [showTooltip, setShowTooltip] = useState(false);
  const { formatNumber } = useNumberFormatter();
  const { formatDate } = useDateFormatter();
  const { program } = useLoyaltyProgram();
  const { account } = useAccount();
  const { pointsExpiration } = program;
  const { pointsExpirationDate, pointsExpirationAmount, pointsBalance } = account;

  const showPointsExpiration =
    pointsExpiration?.status === PointsExpirationStatus.ENABLED && !!pointsExpirationDate && !!pointsExpirationAmount;

  if (!showPointsExpiration) {
    return null;
  }

  const pointsName = program.pointDefinition?.customName;
  const monthsOfInactivity = pointsExpiration?.monthsOfInactivity ?? 0;
  const totalPointsExpirationTag = settings.get(settingsParams.totalPointsExpirationTag);

  const renderStatusText = (): string => {
    const expirationDate = new Date(pointsExpirationDate!);
    const daysRemaining = Math.round(Math.abs((Date.now() - expirationDate.getTime()) / MILLISECONDS_IN_ONE_DAY));

    let dateKey = 'date';
    if (daysRemaining <= 0) {
      dateKey = 'today';
    } else if (daysRemaining <= 30) {
      dateKey = 'days';
    }

    const pointsAmountKey = pointsBalance === pointsExpirationAmount ? 'all' : 'some';
    const pointsNameKey = pointsName ? 'custom' : 'default';

    return t(`points-expiration.status.${dateKey}.${pointsAmountKey}.${pointsNameKey}`, {
      points: pointsExpirationAmount,
      formattedPoints: formatNumber(pointsExpirationAmount),
      pointsName,
      days: daysRemaining,
      formattedDays: formatNumber(daysRemaining),
      date: formatDate(expirationDate),
    });
  };

  const tooltipMessage = t('points-expiration.tooltip', {
    months: monthsOfInactivity,
    formattedMonths: formatNumber(monthsOfInactivity),
  });

  return (
    <div className={style(classes.root, { tiersEnabled })}>
      <Text tagName={totalPointsExpirationTag} className={classes.text}>
        {renderStatusText()}
      </Text>{' '}
      <span onMouseLeave={() => setShowTooltip(false)}>
        <IconTooltip
          className={classes.icon}
          message={tooltipMessage}
          showTooltip={showTooltip}
          onMouseEnter={() => setShowTooltip(true)}
          onClick={() => setShowTooltip(!showTooltip)}
          onClickOutside={() => setShowTooltip(false)}
          icon={<InfoIcon />}
          aria-hidden
          tooltipId="points-expiration-tooltip"
          tooltipProps={{
            appendTo: 'parent',
            minWidth: 240,
            flip: false,
          }}
        />
      </span>
    </div>
  );
};
