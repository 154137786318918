import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  pageTitle: SettingsParamType.Text;
  pageTitleTag: SettingsParamType.String;
  pageDescription: SettingsParamType.Text;
  pageDescriptionTag: SettingsParamType.String;
  button: SettingsParamType.Text;
  getButton: SettingsParamType.Text;
  copyButton: SettingsParamType.Text;
  continueButton: SettingsParamType.Text;
  tiersCurrentTierTitleTag: SettingsParamType.String;
  tiersTierNameTag: SettingsParamType.String;
  totalPointsTitleTag: SettingsParamType.String;
  totalPointsExpirationTag: SettingsParamType.String;
  rewardsRewardTitleTag: SettingsParamType.String;
  rewardsRewardTextTag: SettingsParamType.String;
  emptyStateTitleTag: SettingsParamType.String;
  emptyStateSubtitleTag: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  pageTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.pageTitle'),
  },
  pageTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h1',
  },
  pageDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.pageDescription'),
  },
  pageDescriptionTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  button: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button'),
  },
  getButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-get'),
  },
  copyButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-copy'),
  },
  continueButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-continue'),
  },
  tiersCurrentTierTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  tiersTierNameTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  totalPointsTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  totalPointsExpirationTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  rewardsRewardTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h3',
  },
  rewardsRewardTextTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  emptyStateTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h2',
  },
  emptyStateSubtitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
});
