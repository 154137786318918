import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { ProgressBar, Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { SimpleReward } from '../../../types/domain';
import { useNumberFormatter } from './hooks';
import { useAccount, useLoyaltyProgram } from './hooks/context-hooks';
import { classes } from './reward-progress.st.css';

type RewardProgressProps = {
  reward: SimpleReward;
};

export const RewardProgress = ({ reward }: RewardProgressProps) => {
  const { t } = useTranslation();
  const { program } = useLoyaltyProgram();
  const { account } = useAccount();
  const { formatNumber } = useNumberFormatter();

  const pointsNeeded = (reward.requiredPoints ?? 0) - (account.pointsBalance ?? 0);
  const customPointsName = program.pointDefinition?.customName;

  const label = t(customPointsName ? 'app.reward-progress.points-needed-custom' : 'app.reward-progress.points-needed', {
    pointsName: customPointsName,
    pointsNeeded,
    formattedPointsNeeded: formatNumber(pointsNeeded),
  });

  const ariaLabel = t(customPointsName ? 'app.reward-progress.a11y-label-custom' : 'app.reward-progress.a11y-label', {
    pointsName: customPointsName,
  });

  return (
    <div data-hook={DataHook.RewardsListItemProgressBar} className={classes.root}>
      <div className={classes.progressBarContainer}>
        <ProgressBar
          value={Math.floor(((account.pointsBalance ?? 0) / (reward.requiredPoints ?? 1)) * 100)}
          className={classes.progressBar}
          aria-label={ariaLabel}
        />
      </div>
      <Text data-hook={DataHook.RewardProgressPointsNeeded} className={classes.pointsNeeded}>
        {label}
      </Text>
    </div>
  );
};
